import { useRecoilValue } from 'recoil';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Loader } from '@storybook';
import { useNextStep, useNotification } from 'hooks';
import { useFundInvestmentRequests } from 'views/fund-investment/stores';
import {
	TokenizationResponse,
	PaymentPayload,
	FormState,
	BinInformation,
} from './sdk-types';
import { SessionDetailsState } from 'hooks/use-next-step/stores';

declare global {
	interface Window {
		Finix: {
			CardTokenForm: (
				elementId: string,
				options: any
			) => {
				submit: (
					environment: string,
					applicationId: string,
					callback: (
						err: Error | null,
						res: TokenizationResponse | null
					) => void
				) => void;
			};
		};
	}
}
interface IProps {
	setCardDetailsShow: (value: boolean) => void;
}

export const FundPaySDK: FC<IProps> = ({ setCardDetailsShow }) => {
	const { errorNotification } = useNotification();
	const { submitPayment } = useFundInvestmentRequests();
	const { sessionPayloadDetail, handleNext } = useNextStep();
	const [loading, setLoading] = useState(false);
	const [formLoaded, setFormLoaded] = useState(true);
	const [form, setForm] = useState<ReturnType<
		typeof window.Finix.CardTokenForm
	> | null>(null);
	const [formHasErrors, setFormHasErrors] = useState(true);
	const sessionDetails = useRecoilValue(SessionDetailsState);

	const onFailed = useCallback(
		(res: { statusCode: number; message: string }) => {
			const errorMessage = res?.message || 'Payment failed, please try again!';
			errorNotification(errorMessage);
			setLoading(false);
		},
		[errorNotification]
	);
	const { nodes } = sessionDetails ?? {};
	const { paymentProviders } = nodes ?? {};

	const getApplicationKey = useMemo(() => {
		const finixData = (paymentProviders ?? []).find(
			(item: { providerName: string }) => item?.providerName === 'FINIX'
		);
		return finixData?.details?.applicationId ?? '';
	}, [paymentProviders]);

	const handlePayment = useCallback(
		async (tokenId: string) => {
			const payload: PaymentPayload = {
				tokenId,
				sessionId: sessionPayloadDetail.sessionId,
				paymentMethod: 'CREDIT_CARD',
			};
			await submitPayment(payload, handleNext, onFailed);
		},
		[sessionPayloadDetail.sessionId, submitPayment, handleNext, onFailed]
	);

	const handleSubmit = useCallback(() => {
		if (!form) return;
		setLoading(true);
		form.submit(
			'sandbox',
			getApplicationKey ?? '',
			function (err: Error | null, res: TokenizationResponse | null) {
				if (err) {
					errorNotification('Tokenization failed. Please try again.');
					setLoading(false);
				} else if (res) {
					const token = res.data.id;
					handlePayment(token);
				}
			}
		);
	}, [form, getApplicationKey, errorNotification, handlePayment]);

	useEffect(() => {
		setFormLoaded(true);
		if (typeof window.Finix === 'undefined') {
			// eslint-disable-next-line no-console
			console.error('Finix SDK is not loaded.');
			return;
		}
		const finixForm = window.Finix.CardTokenForm('form', {
			showAddress: false,
			success: {
				color: '#5cb85c',
			},
			onUpdate: function (
				_state: FormState,
				_binInformation: BinInformation,
				formHasErrors: boolean
			) {
				setFormHasErrors(formHasErrors);
			},
			onLoad: function () {
				setFormLoaded(false);
			},
		});

		setForm(finixForm);
	}, []);

	const renderLoader = useMemo(() => {
		return (
			formLoaded && (
				<div className="sdk-screen__loader">
					<Loader dimension={50} type="loader" />
				</div>
			)
		);
	}, [formLoaded]);

	const isPaybuttonDisable = useMemo(() => {
		return formHasErrors;
	}, [formHasErrors]);

	const isbackButtonDisable = useMemo(() => {
		return loading;
	}, [loading]);

	return (
		<Fragment>
			<button
				className="sdk-screen__back-button"
				disabled={isbackButtonDisable}
				onClick={() => setCardDetailsShow(false)}
			>
				<i className="ri-arrow-left-line"></i> Back
			</button>
			<div className={'sdk-screen__wrapper'}>
				{renderLoader}
				<div className="sdk-screen__form" id="form">
					{/* Finix SDK will attach the form here */}
				</div>
			</div>
			<Button
				label={loading ? <Loader type="loader" dimension={24} /> : 'Pay'}
				handleClick={handleSubmit}
				type="button button__filled button__filled--primary button__large button__block"
				disabled={isPaybuttonDisable || loading}
			/>
		</Fragment>
	);
};
