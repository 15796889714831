import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Image } from '@storybook';

import { EmailVerificationConstants, useVerifyEmail } from '../store';
import { BasicInfoDataState } from 'views/basic-information';

import './email-verification.scss';

interface IEmailVerification {
	verificationStatus: string;
}
export const EmailVerification: FC<IEmailVerification> = ({
	verificationStatus,
}) => {
	//local state
	const [counter, setCounter] = useState(40); // Initialize counter to 40 seconds

	//global state
	const basicInfoData = useRecoilValue(BasicInfoDataState);

	//hooks
	const { sendEmail } = useVerifyEmail();
	const isResendAllowed = useMemo(() => counter === 0, [counter]);

	const handleResend = useCallback(async () => {
		await sendEmail(basicInfoData?.firstName ?? '', basicInfoData?.email);
		// Logic to resend the email verification
		setCounter(40); // Reset counter to 40 seconds
	}, [basicInfoData, sendEmail, setCounter]);

	useEffect(() => {
		let timer: NodeJS.Timeout | null = null; // Declare timer variable

		if (counter > 0) {
			timer = setInterval(() => {
				setCounter(prev => prev - 1);
			}, 1000);
		}

		// Cleanup function to clear the timer
		return () => {
			if (timer) {
				clearInterval(timer);
			}
		};
	}, [counter]);

	const verifyRejectContent = useCallback(() => {
		return (
			<div className="verify-modal">
				<div className="verify-modal__image">
					<i className="ri-alert-fill" />
				</div>
				<div className="verify-modal__verify-text-container">
					<div className="verify-modal__title-wrapper">
						<span className="title">
							{EmailVerificationConstants.VerificationDeniedTitle}
						</span>
						<div className="sub-title">
							<span>
								{EmailVerificationConstants.VerificationDeniedMessage}
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}, []);

	const verifyApprovedContent = useCallback(() => {
		const formattedSeconds = String(counter).padStart(2, '0');
		return (
			<>
				<div className="EmailVerification">
					<div className="EmailVerification--img">
						<Image fileName="EmailVerificationIcon.svg" />
					</div>
					<section className="EmailVerification--details">
						<h1>{EmailVerificationConstants.Title}</h1>
						<p>
							{EmailVerificationConstants.EmailSentMessage}{' '}
							<b> {basicInfoData?.email ?? 'N/A'}</b>
						</p>
						<p>{EmailVerificationConstants.CheckInboxMessage}</p>
						<div className="verify-modal__footer verify-modal__footer_verify verify-modal--resend-body">
							<>
								{isResendAllowed ? (
									<div className="verify-modal__footer--resend">
										<span className="footer-text">
											Didn&apos;t receive the text message?
										</span>
										<span
											className="verify-modal__footer--btn"
											onClick={() => handleResend()}
										>
											{EmailVerificationConstants.ResendButton}
										</span>
									</div>
								) : (
									<div className="verify-modal__footer">
										<span className="footer-text">
											{EmailVerificationConstants.TimeRemainingMessage}
											{formattedSeconds}
										</span>
									</div>
								)}
							</>
						</div>
					</section>
				</div>
			</>
		);
	}, [counter, basicInfoData?.email, isResendAllowed, handleResend]);

	return (
		<div className="EmailVerificationContainer">
			{verificationStatus !== 'rejected' ? (
				<>{verifyApprovedContent()}</>
			) : (
				<>{verifyRejectContent()}</>
			)}
		</div>
	);
};
