import { PaymentMethod } from '../components/payments-method/components';
import { IPayInUnitPricingState } from './types';

import { atom } from 'recoil';

export const FundBankDetailsState = atom<any>({
	key: 'bank-details',
	default: {},
});

export const IsInvestingAmountEmptyState = atom<boolean>({
	key: 'is-investing-amount-empty-state-key',
	default: false,
});

export const PayInUnitPricingState = atom<IPayInUnitPricingState>({
	key: 'payIn-unit-pricing-state-key',
	default: {
		unit: '',
		pricePerUnit: '',
	},
});

export const SelectedPaymentMethodState = atom<PaymentMethod>({
	key: 'selected-payment-method-state-key',
	default: 'plaid',
});
