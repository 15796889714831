export const EmailVerificationConstants = {
	Title: 'Verify your email',
	EmailSentMessage: 'A verification email has been sent to',
	CheckInboxMessage:
		'Please check your inbox and spam folder. Kindly click on the link provided in the email and complete the verification process from there.',
	ResendText: 'Didn’t receive the text message?',
	ResendButton: 'Resend',
	VerificationDeniedTitle: 'Email Verification Denied',
	VerificationDeniedMessage:
		'You have denied the verification of this email address. The email has not been verified or linked to your account.',
	TimeRemainingMessage: 'Time remaining for next OTP 00 : ',
};
