import { API_TYPE } from 'constants/api';

import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { AccessTokenState } from 'states';
import { useNotification, useTokenSession } from 'hooks';

export const useVerifyEmail = () => {
	// global state
	const { code: qrID } = useRecoilValue(AccessTokenState);

	//hooks
	const { postTokenSession } = useTokenSession();
	const { errorNotification } = useNotification();

	const sendEmail = useCallback(
		async (name: string, email: string) => {
			const payload = {
				email: email,
				name: name,
			};
			const response = await postTokenSession({
				payload,
				type: API_TYPE.SEND_EMAIL_VERIFICATION,
				code: qrID,
			});
			const { message, statusCode } = response ?? {};

			if (statusCode !== 200) {
				errorNotification(message);
			}
			return response;
		},
		[postTokenSession, qrID, errorNotification]
	);

	const getStatus = useCallback(
		async (statusId: string) => {
			const payload = {
				id: statusId ?? '',
			};
			const response = await postTokenSession({
				payload,
				type: API_TYPE.GET_EMAIL_VERIFICATION_STATUS,
				code: qrID,
			});
			const { message, statusCode } = response ?? {};

			if (statusCode !== 200) {
				errorNotification(message);
			}

			return response;
		},
		[postTokenSession, qrID, errorNotification]
	);

	return { sendEmail, getStatus };
};
