import { FC, Fragment, useCallback, useMemo } from 'react';
import { Button } from '@storybook';

import { PAYMENT_FAILED } from 'constants/common';
import { useNextStep, useNotification } from 'hooks';
import { useRecoilValue } from 'recoil';
import { selectedAccountState, selectedBankState } from 'states';
import { useFundInvestmentRequests } from 'views/fund-investment/stores';

import './payment-summary.scss';

interface IPayoutSummary {
	tokenId?: string;
	handleBack: VoidFunction;
	setLoading: any;
}
export const PayoutSummary: FC<IPayoutSummary> = ({
	tokenId,
	handleBack,
	setLoading,
}) => {
	const selectedAccounts = useRecoilValue(selectedAccountState);
	const selectedBank = useRecoilValue(selectedBankState);

	const { institutionName } = useMemo(() => selectedBank ?? {}, [selectedBank]);

	const { sessionPayloadDetail, handleNext } = useNextStep();
	const { submitPayment } = useFundInvestmentRequests();
	const { errorNotification } = useNotification();

	const { investingAmount } = sessionPayloadDetail;

	const onFailed = useCallback(
		(res: any) => {
			errorNotification(res?.message ?? PAYMENT_FAILED);
		},
		[errorNotification]
	);

	const handlePayment = useCallback(async () => {
		const payload = {
			tokenId,
			sessionId: sessionPayloadDetail.sessionId,
			accountId: selectedAccounts?.accountId,
			accountType: selectedAccounts?.subtype.toUpperCase(),
		};
		setLoading(true);

		await submitPayment(payload, handleNext, onFailed);
		setLoading(false);
	}, [
		tokenId,
		sessionPayloadDetail.sessionId,
		selectedAccounts?.accountId,
		selectedAccounts?.subtype,
		setLoading,
		submitPayment,
		handleNext,
		onFailed,
	]);
	return (
		<Fragment>
			<div className="summary__wraper">
				<div className="summary__head">
					Summary
					<span>Please take a moment and review</span>
				</div>
				<div className="summary__desc__wraper">
					<div className="summary__desc">
						<span>Bank Name : </span>
						<b>{institutionName ?? '--'}</b>
					</div>

					<div className="summary__desc">
						<span>Account Number : </span>
						<b>{selectedAccounts?.mask ?? 0}</b>
					</div>

					<div className="summary__desc desc_3">
						<span>Account Type :</span>
						<b>{selectedAccounts?.subtype ?? ''}</b>
					</div>

					<div className="summary__desc desc_4">
						<span>Amount to receive : </span>
						<b>${Number(investingAmount ?? 0).toFixed(2)}</b>
					</div>
				</div>
			</div>
			<div className="fund-investment__button_wrapper mt-16">
				<Button
					label="Receive Fund"
					handleClick={handlePayment}
					type="button__filled button__filled--primary button__large button__block cursor-pointer"
				/>
				<Button
					label="Back"
					handleClick={handleBack}
					type="button__filled button__filled--secondary button__large button__block cursor-pointer"
				/>
			</div>
		</Fragment>
	);
};
