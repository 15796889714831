import { PaymentMethod, PaymentOption, PaymentSelectorProps } from './types';
import './payment-select.scss';

const paymentOptions: PaymentOption[] = [
	{
		id: 'plaid',
		label: 'Plaid',
		icon: 'ri-bank-line',
	},
	{
		id: 'card',
		label: 'Card',
		icon: 'ri-visa-line',
	},
];

export const PaymentSelector = ({
	selectedMethod,
	onMethodSelect,
	disabled = false,
	error,
}: PaymentSelectorProps) => {
	const handleSelect = (method: PaymentMethod) => {
		if (disabled) return;
		onMethodSelect(method);
	};

	return (
		<div className="payment-selector">
			<div className="payment-selector__option--wrapper">
				{paymentOptions.map(option => (
					<div
						key={option.id}
						className={`payment-selector__option--wrapper ${
							selectedMethod === option.id
								? 'payment-selector__option--wrapper--selected'
								: ''
						} ${disabled ? 'payment-selector__option--wrapper--disabled' : ''}`}
						onClick={() => handleSelect(option.id)}
						role="button"
						tabIndex={disabled ? -1 : 0}
						aria-selected={selectedMethod === option.id}
						aria-disabled={disabled}
					>
						<div className="payment-selector__card">
							<div className="payment-selector__icon">
								<i className={option.icon}></i>
							</div>
							<span className="payment-selector__label">{option.label}</span>
						</div>
						{selectedMethod === option.id && (
							<span className="payment-selector__selected-badge">Selected</span>
						)}
					</div>
				))}
			</div>
			{error && (
				<div className="payment-selector__error" role="alert">
					{error}
				</div>
			)}
		</div>
	);
};
